import Alpine from 'alpinejs';
import persist from '@alpinejs/persist'
import Mustache from 'mustache';
import Konva from 'konva';


window.Konva = Konva;
//
Alpine.plugin(persist)
window.Alpine = Alpine;
Alpine.start();

// import tinymce from 'tinymce/tinymce';

// A theme is also required
// import 'tinymce/themes/silver/theme';

// Any plugins you want to use has to be imported
// import 'tinymce/plugins/paste';
// import 'tinymce/plugins/link';

// Initialize the app
// tinymce.init({
//   selector: '#tiny',
//   plugins: ['paste', 'link']
// });